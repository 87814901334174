import React, {useState} from 'react';
import {Link} from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
import axios from 'axios';
import { useEffect } from 'react';
// geolocalization: read loc field from here https://www.cloudflare.com/cdn-cgi/trace


export default function Pricing () {
  const [currency, setCurrency] = useState('eur');


  useEffect(async () => {
    //const location = await axios.get('https://geolocation.algorithma.workers.dev/');
    const location = await axios.get('https://geolocation.skillmeter.com');

    
    if (location && location.data.continent != 'EU') setCurrency('usd');

  }, []);

    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Plans &amp; Pricing - On-Demand Plan | Skillmeter</title>
            <meta name="description" content="See pricing for Skillmeter and sign up to start hiring faster and better." />
            <meta name="keywords" content="candidate testing, online testing, online assessment, skillmeter pricing" />

        </Helmet>

        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto sm:px-6 lg:px-8">
            <div className="px-8 mx-auto text-center md:px-0">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
                Honest prices, no surprises
              </h2>
              <p className="mt-6 text-lg font-normal text-gray-600 ">
              All plans free for 14 days. <br />
                Get started in less than a minute. Upgrade, downgrade, cancel at any time.
              </p>
            </div>

            <div className='max-w-sm gap-6 mx-auto mt-8 text-right md:mt-16 md:max-w-6xl'>
              <button onClick={()=>setCurrency('eur')} className={'px-2 py-1 ' + ((currency=='eur') ? 'text-white bg-smcolor rounded-xl' : '')}>EUR</button>
              <button onClick={()=>setCurrency('usd')} className={'px-2 py-1 ' + ((currency=='usd') ? 'text-white  bg-smcolor rounded-xl' : '')}>USD</button>
            </div>
            <div className="grid max-w-sm grid-cols-1 gap-6 mx-auto mt-8 text-center md:text-left md:mt-16 md:max-w-6xl md:grid-cols-3">
              <div className="overflow-hidden ">
              </div>

              <div className="relative">

                <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
                  <div className="p-6 lg:px-10 lg:py-8">
                    <h3 className="text-lg font-bold text-gray-900">
                      On-Demand plan
                    </h3>
                    <a href={process.env.SIGNUP_URL + '?plan=4&currency=' + currency} title="Get started" className="inline-flex items-center justify-center px-8 py-3.5 w-full mt-8 text-base font-bold text-gray-900 transition-all duration-200 border-2 border-gray-400 focus:ring-offset-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white hover:bg-opacity-90 hover:bg-gray-900 hover:text-white" role="button">
                      Get Started <br />with 5 free credits
                    </a>

                    <p className="mt-8 text-base font-bold text-gray-900">
                      Plan includes:
                    </p>
                    <ul className="mt-4 space-y-3 text-base font-medium text-gray-900">
                      <li>5 users</li>
                      <li>Questionnaire tests</li>
                      <li>Private tests</li>
                      <li>Prepaid credits (10 CREDITS for {currency === 'usd' ? '$24' : '€20'}, 30 CREDITS for {currency === 'usd' ? '$69' : '€57'}, 100 CREDITS for {currency === 'usd' ? '$204' : '€170'})</li>

                    </ul>
                  </div>
                </div>
              </div>

              <div className="overflow-hidden ">

              </div>
            </div>
          </div>

          <p className='text-center text-gray-400 mt-4'>If you are constantly hiring then you should check our <Link to="/pricing">monthly plans</Link></p>
        </section>



{/** FAQ */}

<section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
            Frequently Asked Questions
          </h2>
        </div>

        <div className="grid max-w-5xl grid-cols-1 mx-auto mt-10 sm:mt-20 md:grid-cols-2 md:gap-x-16 gap-y-8 md:gap-y-12">
          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            What is a credit?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            A credit represents a candidate that has been assigned to a test. If you purchase 10 credits you can use them to measure the skills of up to 10 candidates.

</p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            Can I purchase additional credits?           
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            You can purchase additional credits at any time.
            </p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            How long are the credits valid?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            All Skillmeter credits are valid 1 year (365 days) from the moment of purchase. If they are not used before their expiration date they will become void. You can, however, extend their valability by purchasing an additional pack of credits.
            </p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            How much do the credits cost?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            We currently offer 3 packages that you can choose from anytime: 10 CREDITS for EUR 20 (USD 24), 30 CREDITS for EUR 57 (USD 69), 100 CREDITS for EUR 170 (USD 204)
        </p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            Can I get invoiced instead of paying through a credit card?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            Absolutely. Just contact <a href="mailto:support@skillmeter.com">support</a> to get that set up.


</p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            Will Skillmeter work for any type of business?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            Skillmeter works well for recruiting in IT &amp; engineering as well as any other industries. We have customers from many industries, and they're seeing great results using Skillmeter.

</p>
          </div>
        </div>
      </div>
    </section>

    



        <Footer />
        </>
    );
}